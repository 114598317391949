type Config = {
  lot_management_url?: string | null
}

export default class EnvironmentConfig {
  config: Config = {}

  async loadConfigAsync() {
    try {
      const response = await fetch(`${this.baseURL}client_configs`)
      this.config = await response.json()
    } catch (error) {
      console.warn(
        'Error getting config file, using default global config',
        error
      )
    }
  }

  get baseURL(): string {
    const isLocalhost =
      window.location.hostname === 'localhost' ||
      window.location.hostname === '127.0.0.1'

    const port = isLocalhost ? ':8000' : ''
    const url = `${window.location.protocol}//${window.location.hostname}${port}/o/api/`

    return url
  }

  get lotManagementUrl(): string | null {
    let managementURL: string = this.config?.lot_management_url
    if (!managementURL) {
      managementURL = process.env.lotManagementUrl
    }

    return managementURL
  }
}
