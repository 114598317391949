import { $axios } from '~/utils/api'
import MarketingLeadUser, { MarketingLeadData } from '~/models/marketing'

const END_POINT = '/marketing'

const getMarketingLeadUsers = (
  filters: any
): Promise<Array<MarketingLeadUser>> => {
  return $axios.$get(`${END_POINT}`, { params: filters })
}

const createMarketingLeadUser = (payload: any): Promise<MarketingLeadData> => {
  return $axios.$post(`${END_POINT}`, payload)
}

export { getMarketingLeadUsers, createMarketingLeadUser }
