var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"dropdown-container flex-col lg:flex-row",class:{ 'pointer-events-none opacity-50': !_vm.options.length },on:{"mouseover":function($event){_vm.isHovered = true},"mouseleave":function($event){_vm.isHovered = false}}},[_c('div',{staticClass:"dropdown-title px-1.5 xl:px-4 whitespace-nowrap flex items-center hover:bg-brand-accent-200",class:{
      'text-brand-base': _vm.useTextBrandBase,
    },on:{"click":_vm.dropdownClicked}},[_c('span',{staticClass:"text-sm align-baseline",class:{
        uppercase: _vm.toUppercaseTitle,
        'font-bold': _vm.toBoldFont,
        'dropdown-label': _vm.addPaddingTitle,
      }},[_vm._v("\n      "+_vm._s(_vm.title)+"\n    ")]),_vm._v(" "),_c('span',{staticClass:"arrow-icon"},[_c('fa',{staticClass:"text-xl",attrs:{"icon":['axis', _vm.isDropdownOpen ? 'arrowDropDown' : 'caretRight']}})],1)]),_vm._v(" "),_c('div',{staticClass:"dropdown-menu dropdown-inner",class:{ block: _vm.openedMenu === _vm.title }},[_c('div',{staticClass:"flex flex-col h-40 lg:h-full w-full"},[_c('ul',{staticClass:"p-2 flex-auto overflow-y-auto w-full"},_vm._l((_vm.options),function(option){return _c('li',{key:option.value,staticClass:"group px-2 flex items-center transition-colors text-brand-base"},[_c('CheckboxInput',{attrs:{"id":(_vm.title + "-" + (option.label)),"label":_vm.getLabel(option.label),"value":option.value,"checked":_vm.selectedOptions.some(function (ref) {
          var value = ref.value;

          return value === option.value;
      }),"disabled":_vm.isDisabled(option.value, option.type)},on:{"input":_vm.handleCheck}}),_vm._v(" "),(option.lots_count && _vm.showBuilderCount)?_c('div',{staticClass:"match-count"},[_vm._v("\n            "+_vm._s(option.lots_count)+"\n          ")]):_vm._e()],1)}),0)])])])}
var staticRenderFns = []

export { render, staticRenderFns }