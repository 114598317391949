import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { CustomQueryParam, LotInfo, Project } from '~/models'

@Module({
  name: 'consumer-map',
  stateFactory: true,
  namespaced: true,
})
export default class ConsumerMap extends VuexModule {
  activeView: 'map' | 'grid' = 'map'
  propertiesCount: number = 0
  totalHomesFiltered: number = 0
  totalHomeSitesFiltered: number = 0
  plansCount: number = 0
  totalPlansFiltered: number = 0
  filterVisibilitySettings: Object = {}
  debouncedFilteredRequests: Function = null
  currentProject: Project = null
  lotInfo: LotInfo = null
  filterParams: any = null
  loadingHomes: boolean = false
  loadingHomeSites: boolean = false
  parentPageBaseURL: string = ''
  customQueryParams: CustomQueryParam = null
  activeTab: string = null

  get propertiesShown(): number {
    return this.totalHomesFiltered + this.totalHomeSitesFiltered || 0
  }

  get debouncedFilteredRequestExist() {
    return this.debouncedFilteredRequests
  }

  get queryParams(): CustomQueryParam {
    return this.customQueryParams
  }

  get project(): Project {
    return this.currentProject
  }

  get isConsumerMapPlansDisabled(): string {
    if (!this.project || !this.project.config) {
      return 'false'
    }
    return this.project.config.consumer_map_v3_disable_plans
  }

  get isMapHiddenConsumerMapV3(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.hide_map_consumer_map_v3
  }

  get isCondoFilteringEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.multi_family_unit_filtering
  }

  get reorderSidepanelPropertyTabs(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.reorder_sidepanel_property_tabs
  }

  get isCustomBadgesEnabled(): boolean {
    if (!this.project || !this.project.config) {
      return false
    }
    return this.project.config.enable_custom_badges
  }

  get activeMapTab(): string {
    return this.activeTab
  }

  @Action({ rawError: true })
  handleFilterChange(filters: any[]): void {
    this.debouncedFilteredRequests(filters)
  }

  @Mutation
  setDebouncedFilteredRequests(debounce: Function) {
    this.debouncedFilteredRequests = debounce
  }

  @Mutation
  unsetDebouncedFilteredRequests() {
    this.debouncedFilteredRequests = null
  }

  @Mutation
  setCustomQueryParams(queryParams: CustomQueryParam) {
    this.customQueryParams = queryParams
  }

  @Mutation
  setPropertiesCount(properties: number) {
    this.propertiesCount = properties
  }

  @Mutation
  setTotalHomeSitesFiltered(totalHomeSitesFiltered: number) {
    this.totalHomeSitesFiltered = totalHomeSitesFiltered
  }

  @Mutation
  setTotalHomesFiltered(totalHomesFiltered: number) {
    this.totalHomesFiltered = totalHomesFiltered
  }

  @Mutation
  setPlansCount(plans: number) {
    this.plansCount = plans
  }

  @Mutation
  setTotalPlansFiltered(totalPlansFiltered: number) {
    this.totalPlansFiltered = totalPlansFiltered
  }

  @Mutation
  setFilterVisibilitySettings(filterVisibilitySettings: Object) {
    this.filterVisibilitySettings = filterVisibilitySettings
  }

  @Mutation
  setCurrentProject(project: Project) {
    this.currentProject = project
  }

  @Mutation
  setLotInfo(lotInfo: LotInfo) {
    this.lotInfo = lotInfo
  }

  @Mutation
  setFilterParams(filterParams: any) {
    this.filterParams = filterParams
  }

  @Mutation
  setLoadingHomes(loadingHomes: boolean) {
    this.loadingHomes = loadingHomes
  }

  @Mutation
  setLoadingHomeSites(loadingHomeSites: boolean) {
    this.loadingHomeSites = loadingHomeSites
  }

  @Mutation
  toggleActiveView(view: 'map' | 'grid') {
    this.activeView = view === 'map' ? 'grid' : 'map'
  }

  @Mutation
  setActiveView(view: 'map' | 'grid') {
    this.activeView = view
  }

  @Mutation
  setActiveMapTab(tab: string) {
    this.activeTab = tab
  }

  @Mutation
  setParentPageBaseURL() {
    const parentPageBaseURL = new window.URLSearchParams(
      window.location.search
    ).get('parent_page_base_url')

    /*
      Allowing parentPageBaseURL to be reset to null/undefined would make it so that
      the app must pass the base url on every view change. If you are trying to test this
      you have to manually empty your local storage to clear.
      
      The client can pass in their base url with or without a trailing slash.

      A #/ will be appended before our own urls to prevent the browser going directly
      to that end point on the client server, and instaed passing that information to our iframe.
    */
    if (parentPageBaseURL) {
      this.parentPageBaseURL = parentPageBaseURL
    }
  }

  @Mutation
  clear() {
    this.customQueryParams = null
  }
}
