const removeCookies = () => {
  const cookies: string[] = document.cookie.split(';')
  cookies.forEach((cookie: string) => {
    const index = cookie.indexOf('=')
    const name = index > -1 ? cookie.substring(0, index) : cookie
    document.cookie = name + '=;expires=Thu, 05 Dec 2000 00:00:00 GMT'
  })
}

const removeCookie = (name: string) => {
  document.cookie = name + '=;expires=Thu, 05 Dec 2000 00:00:00 GMT; path=/'
}
export { removeCookies, removeCookie }
