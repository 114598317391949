








































import { Component, mixins, Emit, Prop } from 'nuxt-property-decorator'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'
import ButtonGroupV3 from '~/components/sales_map_v3/ButtonGroupV3.vue'
import MapSelect from '~/components/consumer_map/mapSelect.vue'
import globalStyling from '~/mixins/globalStyling'
import { ConsumerMapRoutes } from '~/enums/consumerMap'

@Component({
  components: {
    MapDropdownFilter,
    ButtonGroupV3,
    MapSelect,
  },

  mixins: [globalStyling],
})
export default class OtherFilters extends mixins(globalStyling) {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => {} }) selectedGarages: any
  @Prop({ default: (): any => {} }) selectedStories: any
  @Prop({ default: (): any => {} }) garagesOptions: any
  @Prop({ default: (): any => {} }) storiesOptions: any
  @Prop({ default: (): any => {} }) selectedArchitecture: any
  @Prop({ default: (): any => {} }) architectureOptions: any

  @Emit('updateGaragesFilter')
  updateGaragesFilter() {}

  @Emit('updateStoriesFilter')
  updateStoriesFilter() {}

  @Emit('handleArchCheck')
  handleArchCheck() {}

  getActiveRoute(): string {
    return this.$route.path.split('/').pop()
  }

  get isPlansRoute(): boolean {
    return this.getActiveRoute() === ConsumerMapRoutes.PLANS
  }

  handleChangeMenu(e: any) {
    e ? this.$emit('changeMenu', this.title) : this.$emit('changeMenu', '')
  }
}
