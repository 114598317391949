import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import { getFilters } from '~/api/filters.api'
import { FilterFields } from '~/models/filters'
import BaseState from '~/models/state/baseState'
import { HomeSalesStatusLabels } from '~/enums/lots'
import { getMiscTypes } from '~/api/projects.api'
import { projectStore } from '~/store'
import { getBuilders } from '~/utils/contracts'

type Filters = {
  beds: Array<FilterFields>
  baths: Array<FilterFields>
  plans: Array<FilterFields>
  styles: Array<FilterFields>
  garages: Array<FilterFields>
  stories: Array<FilterFields>
  planSqft: Array<FilterFields>
  builders: Array<FilterFields>
  lotSizes: Array<FilterFields>
  lotTypes: Array<FilterFields>
  planPrices: Array<FilterFields>
  masterPlans: Array<FilterFields>
  segmentations: Array<FilterFields>
  lotNeighborhoods: Array<FilterFields>
  constructionPhases: Array<FilterFields>
  materialCategories: Array<FilterFields>
  architecturalStatus: Array<FilterFields>
  lotDevelopmentStatus: Array<FilterFields>
  blocks: Array<FilterFields>
  builderContractsStatus: Array<FilterFields>
  plats: Array<FilterFields>
  availability: Array<FilterFields>
  orderBy: Array<FilterFields>
  miscTypes: Array<FilterFields>
  builderContracts: Array<FilterFields>
  subPhases: Array<FilterFields>
  netBuilders: Array<FilterFields>
}

@Module({
  name: 'filters',
  stateFactory: true,
  namespaced: true,
})
export default class SFilters extends VuexModule implements BaseState {
  buildersData: FilterFields[] = null
  materialCategoriesData: FilterFields[] = null
  lotSizesData: FilterFields[] = null
  stylesData: FilterFields[] = null
  lotNeighborhoodsData: FilterFields[] = null
  constructionPhasesData: FilterFields[] = null
  lotTypesData: FilterFields[] = null
  masterPlansData: FilterFields[] = null
  plansData: FilterFields[] = null
  architecturalStatusData: FilterFields[] = null
  planPricesData: FilterFields[] = null
  planSqftData: FilterFields[] = null
  bedsData: FilterFields[] = null
  bathsData: FilterFields[] = null
  storiesData: FilterFields[] = null
  garagesData: FilterFields[] = null
  lotDevelopmentStatusData: FilterFields[] = null
  segmentationsData: FilterFields[] = null
  blocksData: FilterFields[] = null
  builderContractsStatusData: FilterFields[] = null
  platsData: FilterFields[] = null
  availabilityData: FilterFields[] = null
  miscTypesData: FilterFields[] = null
  builderContractsData: FilterFields[] = null
  subPhasesData: FilterFields[] = null
  netBuildersData: FilterFields[] = []

  loadingFilters: boolean = true
  filtersLoaded: boolean = false

  @Action({ rawError: true })
  async retrieveFilters(builder?: string): Promise<void> {
    if (!this.filtersLoaded) {
      this.setLoadingFilters(true)

      const constructionPhases: FilterFields[] = await getFilters(
        'construction_phases'
      )

      const builders: FilterFields[] = await getBuilders()
      const netBuilders: FilterFields[] = []

      const materialCategories: FilterFields[] = await getFilters(
        'material_categories'
      )
      const lotSizes: FilterFields[] = await getFilters('lot_sizes', builder)
      const styles: FilterFields[] = await getFilters('styles')
      const lotNeighborhoods: FilterFields[] = await getFilters(
        'lot_neighborhoods'
      )
      const lotTypes: FilterFields[] = await getFilters('lot_types')
      this.setlotTypes(lotTypes)

      const masterPlans: FilterFields[] = await getFilters('master_plans')
      const plans: FilterFields[] = await getFilters('plans')
      const architecturalStatus: FilterFields[] = await getFilters(
        'architectural_status'
      )
      const planPrices: FilterFields[] = await getFilters('plan_prices')
      const planSqft: FilterFields[] = await getFilters('plan_sqft')
      const beds: FilterFields[] = await getFilters('plan_beds')
      const baths: FilterFields[] = await getFilters('plan_baths')
      const stories: FilterFields[] = await getFilters('plan_stories')
      const garages: FilterFields[] = await getFilters('plan_garage')
      const lotDevelopmentStatus: FilterFields[] = await getFilters(
        'lot_development_status'
      )
      const segmentations: FilterFields[] = await getFilters('segmentations')
      const blocks: FilterFields[] = await getFilters('blocks')
      const builderContractsStatus: FilterFields[] = await getFilters(
        'builder_contracts_status'
      )
      const plats: FilterFields[] = await getFilters('plats')
      const availability: FilterFields[] = await getFilters('plan_available')
      const builderContracts: FilterFields[] = await getFilters(
        'builder_contracts'
      )
      const orderBy: FilterFields[] = [
        {
          id: 'newest',
          value: 'new',
        },
        {
          id: 'oldest',
          value: 'old',
        },
      ]
      const miscTypes = await getMiscTypes(projectStore.currentProject?.id)
      const subPhases = await getFilters('sub_phases')
      this.setFilters({
        builders,
        materialCategories,
        lotSizes,
        styles,
        lotNeighborhoods,
        constructionPhases,
        lotTypes,
        masterPlans,
        plans,
        architecturalStatus,
        planPrices,
        planSqft,
        beds,
        baths,
        stories,
        garages,
        lotDevelopmentStatus,
        segmentations,
        blocks,
        builderContractsStatus,
        plats,
        availability,
        orderBy,
        miscTypes,
        builderContracts,
        subPhases,
        netBuilders,
      })
    }
  }

  @Mutation
  setFiltersLoaded(loaded: boolean) {
    this.filtersLoaded = loaded
  }

  @Action({ rawError: true })
  async retrieveBuilderContracts(): Promise<void> {
    const builderContracts: FilterFields[] = await getFilters(
      'builder_contracts'
    )
    this.setBuilderContracts(builderContracts)
  }

  @Mutation
  setBuilderContracts(builderContracts: FilterFields[]) {
    this.builderContractsData = builderContracts
  }

  @Mutation
  setLoadingFilters(isLoadig: boolean) {
    this.loadingFilters = isLoadig
  }

  @Mutation
  setlotTypes(lotTypesData: FilterFields[]) {
    this.lotTypesData = lotTypesData
    if (this.lotTypesData?.length) {
      this.lotTypesData.forEach((lotType) => {
        let lotTypeId = lotType.id as string
        lotTypeId = lotTypeId.toUpperCase().replace(' ', '_').replace('-', '_')
        if (HomeSalesStatusLabels[lotTypeId]) {
          lotType.value = HomeSalesStatusLabels[lotTypeId]
        }
      })
    }
  }

  get areFilterloading(): boolean {
    return this.loadingFilters
  }

  get builders(): FilterFields[] {
    return this.buildersData
  }

  get materialCategories(): FilterFields[] {
    return this.materialCategoriesData
  }

  get lotSizes(): FilterFields[] {
    return this.lotSizesData
  }

  get styles(): FilterFields[] {
    return this.stylesData
  }

  get lotNeighborhoods(): FilterFields[] {
    return this.lotNeighborhoodsData
  }

  get constructionPhases(): FilterFields[] {
    return this.constructionPhasesData
  }

  get lotTypes(): FilterFields[] {
    return this.lotTypesData
  }

  get masterPlans(): FilterFields[] {
    return this.masterPlansData
  }

  get plans(): FilterFields[] {
    return this.plansData
  }

  get architecturalStatus(): FilterFields[] {
    return this.architecturalStatusData
  }

  get filterPlanPrices(): FilterFields[] {
    return this.planPricesData
  }

  get filterPlanSqft(): FilterFields[] {
    return this.planSqftData
  }

  get filterPlanBeds(): FilterFields[] {
    return this.bedsData
  }

  get filterPlanBaths(): FilterFields[] {
    return this.bathsData
  }

  get filterPlanStories(): FilterFields[] {
    return this.storiesData
  }

  get filterPlanGarages(): FilterFields[] {
    return this.garagesData
  }

  get lotDevelopmentStatus(): FilterFields[] {
    return this.lotDevelopmentStatusData
  }

  get segmentations(): FilterFields[] {
    return this.segmentationsData
  }

  get blocks(): FilterFields[] {
    return this.blocksData
  }

  get builderContractsStatus(): FilterFields[] {
    return this.builderContractsStatusData
  }

  get plats(): FilterFields[] {
    return this.platsData
  }

  get availability(): FilterFields[] {
    return this.availabilityData
  }

  get miscTypes(): FilterFields[] {
    return this.miscTypesData
  }

  get builderContracts(): FilterFields[] {
    return this.builderContractsData
  }

  get subPhases(): FilterFields[] {
    return this.subPhasesData
  }

  get netBuilders(): FilterFields[] {
    return this.netBuildersData
  }

  @Mutation
  setFilters(filters: Filters): void {
    this.buildersData = filters.builders
    this.materialCategoriesData = filters.materialCategories
    this.lotSizesData = filters.lotSizes
    this.stylesData = filters.styles
    this.lotNeighborhoodsData = filters.lotNeighborhoods
    this.constructionPhasesData = filters.constructionPhases
    this.lotTypesData = filters.lotTypes
    this.masterPlansData = filters.masterPlans
    this.plansData = filters.plans
    this.architecturalStatusData = filters.architecturalStatus
    this.planPricesData = filters.planPrices
    this.planSqftData = filters.planSqft
    this.bedsData = filters.beds
    this.bathsData = filters.baths
    this.storiesData = filters.stories
    this.garagesData = filters.garages
    this.lotDevelopmentStatusData = filters.lotDevelopmentStatus
    this.segmentationsData = filters.segmentations
    this.blocksData = filters.blocks
    this.builderContractsStatusData = filters.builderContractsStatus
    this.platsData = filters.plats
    this.availabilityData = filters.availability
    this.loadingFilters = false
    this.filtersLoaded = true
    this.miscTypesData = filters.miscTypes
    this.builderContractsData = filters.builderContracts
    this.subPhasesData = filters.subPhases
    this.netBuildersData = filters.netBuilders || []
  }

  @Mutation
  clear() {
    this.buildersData = null
    this.materialCategoriesData = null
    this.lotSizesData = null
    this.stylesData = null
    this.lotNeighborhoodsData = null
    this.constructionPhasesData = null
    this.lotTypesData = null
    this.masterPlansData = null
    this.plansData = null
    this.architecturalStatusData = null
    this.planPricesData = null
    this.planSqftData = null
    this.bedsData = null
    this.bathsData = null
    this.storiesData = null
    this.garagesData = null
    this.lotDevelopmentStatusData = null
    this.segmentationsData = null
    this.blocksData = null
    this.builderContractsStatusData = null
    this.builderContractsData = null
    this.platsData = null
    this.availabilityData = null
    this.miscTypesData = null
    this.subPhasesData = null

    this.loadingFilters = false
    this.filtersLoaded = false
    this.netBuildersData = null
  }
}
