



















































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import { useVuelidate } from '@vuelidate/core'
import { reactive } from '@vue/composition-api'
import ActionAlert from '~/components/shared/alerts/ActionAlert.vue'
import { DynamicForm } from '~/models/forms'
import { InputType } from '~/enums/inputType'
import { Realtor, Agency } from '~/models'
import {
  createRealtor,
  getRealtor,
  editRealtor,
  realtorHasEmailCount,
} from '~/api/realtor.api'
import {
  createRealtorAgency,
  editRealtorAgency,
} from '~/api/realtor_agency.api'
import Style from '~/models/style'
import FormStep from '~/models/forms/FormStep'
import { getFormMetadata } from '~/api/form_views.api'
import debounce from '~/utils/debounce'

@Component({
  components: {
    Input: () => import('~/components/shared/inputs/Input.vue'),
    ActionAlert,
  },
})
export default class RealtorFormComponent extends Vue {
  @Prop({ default: {} }) realtor: Realtor

  formControls: any[] = []
  formMetadata: any[]
  form: any = null
  realtorFormGroup: any = null
  getPreviewFieldsInformation: Function
  inputType: typeof InputType = InputType
  editMode: boolean = false
  realtorPicked: Realtor = null
  errors: any = null
  validators: any = null
  v$: any = null
  styles: Style[] = []
  loading: boolean = false
  emailStatusMessage: string = ''
  emailWarning: boolean = false
  loadMatchingEmailCountDebounced: Function

  isRequired(validators: any, control: any) {
    return this.findValidator(validators, control.id)?.required || false
  }

  findValidator(validators: any, property: string, value: any = undefined) {
    const modelKeys = Object.keys(validators)
    if (modelKeys.length && modelKeys.includes(property)) {
      if (validators[property] !== undefined) {
        value = validators[property]
      }
    } else {
      const modelList = Object.values(validators)
      modelList.forEach((deepModel: any) => {
        if (
          typeof deepModel === 'object' &&
          deepModel !== null &&
          Object.keys(deepModel).length
        ) {
          value = this.findValidator(deepModel, property, value)
        }
      })
    }

    if (value) {
      return value
    }
  }

  mounted() {
    this.loadMatchingEmailCountDebounced = debounce(this.onEmailInput, 500)
  }

  created() {
    this.loading = true
    getFormMetadata('realtor').then((metadata) => {
      this.loading = false
      this.formMetadata = metadata.form || []
      if (this.formMetadata.length) {
        this.setupForm({ metadata: this.formMetadata })
        this.initForm()
      }
    })
  }

  setupForm(options: { metadata?: FormStep[] }): void {
    const { metadata } = options
    const form = new DynamicForm(metadata, this, 'realtorFormGroup')
    this.realtorFormGroup = reactive(form.model)
    this.validators = form.validators
    this.updateValidatorInstance(this.validators)
    this.form = form.formMetadata
  }

  updateValidatorInstance(validators: any = null) {
    if (validators) {
      this.v$ = useVuelidate({ ...validators }, this.realtorFormGroup)
    }
  }

  async initForm() {
    if (this.realtor) {
      this.editMode = true
      await this.fillForm()
    }
  }

  async fillForm(): Promise<void> {
    try {
      const realtor = await getRealtor(this.realtor.id)
      this.realtorPicked = { ...realtor }
      this.getFormPopulated(realtor, this.formMetadata, this.realtorFormGroup)
    } catch (exception) {
      console.log(exception)
    }
  }

  getFormPopulated(
    realtor: Realtor,
    metadata: FormStep[],
    formModel: any,
    index: string = ''
  ): void {
    metadata.forEach((stepForm: any) => {
      const { step, nested, controls } = stepForm
      if (step) {
        this.getFormPopulated(realtor, controls, formModel, step)
      } else if (nested) {
        this.getFormPopulated(realtor, controls, formModel[index], stepForm.id)
      } else if (stepForm?.settings?.read_from) {
        const value = stepForm.settings.read_from
          .split('.')
          .reduce((acc: any, value: any) => {
            return acc === '' || acc === undefined
              ? realtor[value]
              : acc === null
              ? acc
              : acc[value]
          }, '')
        formModel[stepForm.id] = value
        console.log('form model, ', formModel)
      }
    })
  }

  showFieldsMessageErrors(control: any) {
    this.errors = []
    if (control.$anyDirty && control.$errors.length > 0) {
      this.showControlErrorMessages(control.$errors)
    }
  }

  showControlErrorMessages(errors: any) {
    errors.forEach((error: any) => {
      this.errors.push(error.$message)
    })
  }

  save(shouldSave: boolean): void {
    if (!shouldSave) {
      this.closeModal()
      return
    }
    this.errors = []
    if (this.v$.value.$invalid) {
      this.validateForm()
      return
    }
    const formData = this.getDataFormCleaned(this.realtorFormGroup)
    this.callRealtorApi(formData)
  }

  async callRealtorApi(formData: any): Promise<any> {
    try {
      const realtor = await this.createOrEditRealtor(formData, this.editMode)
      if (realtor) {
        this.$toast.success(
          !this.editMode ? 'Realtor created' : 'Realtor updated'
        )
        this.closeModal(realtor)
      }
    } catch (errors) {
      for (const error in errors) {
        this.$toast.error(errors[error][0])
      }
    }
  }

  getDataFormCleaned(form: any): any {
    let objCleaned = {}
    for (const key in form) {
      if (form[key] || form[key] === false) {
        if (typeof form[key] === 'object' && Object.keys(form[key]).length) {
          objCleaned = {
            ...objCleaned,
            ...{
              [key]: form[key].length
                ? [...form[key]]
                : { ...this.getDataFormCleaned(form[key]) },
            },
          }
        } else {
          objCleaned = {
            ...objCleaned,
            ...{ [key]: form[key] },
          }
        }
      } else {
        objCleaned = {
          ...objCleaned,
          ...{ [key]: null },
        }
      }
    }
    return objCleaned
  }

  validateForm() {
    this.v$.value.$validate()
    this.showFieldsMessageErrors(this.v$.value)
  }

  async safeUs(event: boolean) {
    if (!event) {
      this.closeModal()
      return
    }
    this.errors = null
    if (!this.realtorFormGroup.valid) {
      this.errors = this.realtorFormGroup.getErrorSummary(true)
      return
    }
    const formData = this.realtorFormGroup.value
    try {
      const realtor = await this.createOrEditRealtor(formData, this.editMode)
      if (realtor) {
        this.$toast.success(
          !this.editMode ? 'Realtor created' : 'Realtor updated'
        )
        this.closeModal(realtor)
      }
    } catch (error) {
      this.$toast.error(error)
    }
  }

  @Emit('onClose')
  closeModal(realtor: Realtor = null): Realtor {
    return realtor
  }

  async createOrEditRealtor(
    formData: any,
    editMode: boolean = false
  ): Promise<Realtor> {
    const {
      first_name,
      last_name,
      email,
      phone,
      agency_name,
      agency_branch,
      realtor_team,
    } = formData || {}
    let realtor: Realtor = null
    let agency: Agency = null
    if (editMode && this.realtorPicked?.agency?.id) {
      agency = await editRealtorAgency(this.realtorPicked.agency.id, {
        name: agency_name,
        branch: agency_branch,
      })
    } else if (agency_name || agency_branch) {
      agency = await createRealtorAgency({
        name: agency_name,
        branch: agency_branch,
      })
    }

    if (editMode) {
      realtor = await editRealtor(this.realtorPicked.id, {
        first_name,
        last_name,
        email,
        phone,
        agency_id: agency?.id || null,
        realtor_team,
      })
      realtor = { ...realtor, id: this.realtorPicked.id }
    } else {
      realtor = await createRealtor({
        first_name,
        last_name,
        email,
        phone,
        agency_id: agency?.id || null,
        realtor_team,
      })
    }
    return realtor
  }

  async onEmailInput(controlId: string, input: string) {
    if (controlId !== 'email') return

    try {
      const { count: matchingCount = 0 } = await realtorHasEmailCount(input)
      this.emailWarning = matchingCount > 0
      this.emailStatusMessage = `${matchingCount} account${
        matchingCount > 1 ? 's' : ''
      }`
    } catch (error) {
      console.error('Error fetching email count:', error)
    }
  }
}
