export enum InputType {
  text = 'text',
  textTypeahead = 'textTypeahead',
  realtorTextTypeahead = 'realtorTextTypeahead',
  password = 'password',
  select = 'select',
  date = 'date',
  toggle = 'toggle',
  fileUpload = 'fileUpload',
  number = 'number',
  buttonSelect = 'buttonSelect',
  buttonMultiSelect = 'buttonMultiSelect',
  elevations = 'elevations',
  materials = 'materials',
  currency = 'currency',
  children = 'children',
  checkboxGroup = 'checkboxGroup',
  checkbox = 'checkbox',
  dateRange = 'dateRange',
  bulkList = 'bulkList',
  search = 'search',
  richTextField = 'richTextField',
  toggleButton = 'toggleButton',
  marketingInput = 'marketingInput',
}
