import { Module, VuexModule, Mutation, Action } from 'vuex-module-decorators'
import BaseState from '~/models/state/baseState'
import { FavouriteLot, FavouritesCol } from '~/models'
import { getFavourites, storageAvailable } from '~/utils/localStorage'

@Module({
  name: 'favorites',
  stateFactory: true,
  namespaced: true,
})
export default class Favorites extends VuexModule implements BaseState {
  favs: FavouritesCol = {} as FavouritesCol

  get favCount(): number {
    // lengths of other favourites types to be added to this calculation in the future
    if (this.favs) {
      return this.favs?.lots?.length || 0
    }
    return 0
  }

  //, item_type: string
  @Action({ rawError: true })
  addFavorite(item_obj: FavouriteLot): boolean {
    if (item_obj.item_type === 'lot') {
      const lot: FavouriteLot = item_obj as FavouriteLot
      const state: any = this.context.state as any
      if (
        state.favs.lots.find((elem: FavouriteLot) => elem.id === lot.id) ===
        undefined
      ) {
        this.context.commit('add', item_obj)
        return true
      }
    }
    return false
  }

  @Action({ rawError: true })
  removeFavorite(item_obj: FavouriteLot): boolean {
    const { id, plan_id, elevation_id, item_type } = item_obj
    const state: any = this.context.state as any
    if (item_type === 'lot') {
      const foundElem = state.favs.lots.find(
        (elem: FavouriteLot) =>
          elem.id === id &&
          elem.plan_id === plan_id &&
          elem.elevation_id === elevation_id
      )
      if (foundElem) {
        const { id, plan_id, elevation_id, item_type } = item_obj
        this.context.commit('remove', {
          id,
          plan_id,
          elevation_id,
          item_type,
        })
      }
    }
    return true
  }

  @Mutation
  add(item_obj: { item_type: string }): void {
    if (item_obj.item_type === 'lot') {
      this.favs.lots.push(item_obj as FavouriteLot)
    }
  }

  @Mutation
  remove(payload: any): void {
    const { id, plan_id, elevation_id, item_type } = payload
    if (item_type === 'lot') {
      const index = this.favs.lots.findIndex(
        (elem) =>
          elem.id === id &&
          elem.plan_id === plan_id &&
          elem.elevation_id === elevation_id
      )

      if (index >= 0) {
        this.favs.lots.splice(index, 1)
      }
    }
  }

  @Mutation
  loadFavorites(): void {
    try {
      if (storageAvailable()) {
        const favCol = getFavourites()
        if (favCol !== null) {
          this.favs = favCol
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  @Mutation
  clear(): void {
    this.favs = null
  }
}
