import { Realtor } from '~/models'
import { RealtorCount } from '~/models/realtor'
import { $axios } from '~/utils/api'

const USERS_ENDPOINT = '/realtor'

const getRealtor = (id: number): Promise<Realtor> =>
  $axios.$get(`${USERS_ENDPOINT}/${id}`)

const createRealtor = (formData: any): Promise<Realtor> =>
  $axios.$post(`${USERS_ENDPOINT}`, formData)

const editRealtor = (id: number, formData: any): Promise<Realtor> =>
  $axios.$put(`${USERS_ENDPOINT}/${id}`, formData)

const realtorHasEmailCount = (email: string): Promise<RealtorCount> =>
  $axios.$post(`${USERS_ENDPOINT}/email-count?email=${email}`)

export { createRealtor, getRealtor, editRealtor, realtorHasEmailCount }
