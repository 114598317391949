








































































import { Component, Emit, Prop, Watch } from 'nuxt-property-decorator'
import ClientOnly from 'vue-client-only'
import BaseInputComponent from './BaseInput.vue'
import TitleInput from './TitleInput.vue'

@Component({
  components: {
    TitleInput,
    ClientOnly,
  },
})
export default class DateInput extends BaseInputComponent {
  date: any = ''
  today: any = ''

  @Prop({ default: 'MM/dd/yyyy' })
  format: string

  @Prop({ default: 'left' })
  align: string

  @Prop({ default: 'bottom' })
  position: string

  @Prop()
  disableDatesFrom: Date

  @Prop()
  disableDatesTo: Date

  @Prop()
  disableToHour: Number

  @Prop()
  soldOnToday: boolean

  @Prop({ default: false })
  isLargeInput: boolean

  mounted(): void {
    this.parseDate()
  }

  parseDate(): void {
    if (this.value) {
      const formatDate = new Date(this.value)
      this.date = this.$dateFns.addMinutes(
        formatDate,
        formatDate.getTimezoneOffset()
      )
    } else if (this.soldOnToday) {
      this.date = new Date()
    } else {
      this.date = null
    }
    this.handleInput()
  }

  @Watch('value')
  onDateChange(value: any) {
    this.date = value
  }

  @Emit('input')
  handleInput(): string {
    if (this.date) {
      return this.$dateFns.format(this.date, this.format)
    }
  }

  disableDates(): { from?: Date; to?: Date } {
    let disable = {}

    if (this.disableDatesFrom) {
      disable = {
        ...disable,
        from: this.disableDatesFrom,
      }
    }

    const currentHour = new Date().getHours()
    const currentDay = new Date().getDate()
    const isTooLateInDay = this.disableToHour
      ? currentHour >= this.disableToHour
      : false
    const isDisabledToToday = this.disableDatesTo
      ? this.disableDatesTo.getDate() <= currentDay
      : false
    if (isTooLateInDay && isDisabledToToday) {
      this.disableDatesTo.setDate(this.disableDatesTo.getDate() + 1)
    }

    if (this.disableDatesTo) {
      disable = {
        ...disable,
        to: this.disableDatesTo,
      }
    }

    return disable
  }

  @Emit('input')
  clearDate(event: Event): void {
    event.preventDefault()
    event.stopPropagation()

    this.date = null
    return this.date
  }

  @Emit('validateField')
  handleBlur(): any {}
}
