import {
  required,
  email,
  minLength,
  maxLength,
  minValue,
  maxValue,
  between,
  alphaNum,
  numeric,
  integer,
  decimal,
  url,
  helpers,
} from '@vuelidate/validators'
import FormStep from './FormStep'
import { LabelSizes } from '~/enums/labelSizes'
import { isValidZipcode } from '~/api/zipcode.api'
import {
  isValidSoldDateForCustomContract,
  isValidSoldDateForCustomContractMessage,
} from '~/utils/utils'
import { InputType } from '~/enums/inputType'

const VARIANCE_INFO = [
  'variance_name',
  'variance_phone',
  'variance_email',
  'variance_reason',
]

const BUYER_STEP_FIELDS = [
  'first_name',
  'last_name',
  'email',
  'phone',
  'address_zipcode',
  'address_street_name',
  'age',
  'employer_name',
  'employer_zipcode',
]

export default class DynamicForm {
  formMetadata: FormStep[]
  validatorsForm = {}
  formModel = {}
  instance: any
  modelName: string

  constructor(formMetadata: FormStep[], formInstance: any, modelName: string) {
    this.instance = formInstance
    this.modelName = modelName
    this.formMetadata = this.processMetadata(formMetadata)
    this.formModel = this.createModel(formMetadata)
    this.processWatchers(formMetadata)
    this.processOptionalRequiredFields(formMetadata)
    this.validatorsForm = this.processValidators(formMetadata)
  }

  private processMetadata(metadata: any[]) {
    const processedMetadata = metadata.map((formStep) => {
      const { step, nested, controls } = formStep
      if (step) {
        this.processMetadata(controls)
      } else if (nested) {
        formStep.parentClass = formStep.parentClass || 'w-full mb-6'
        this.processMetadata(controls)
      } else {
        if (BUYER_STEP_FIELDS.includes(formStep.id)) {
          formStep.parentClass = formStep.parentClass || 'w-1/2 pr-2 mb-6'
        } else {
          formStep.parentClass = formStep.parentClass || 'w-full mb-6'
        }

        if (formStep.type === 'materials') {
          formStep.settings = {
            ...formStep.settings,
            showSelectors: true,
            filterMaterials: false,
            options: [],
            enabled: true,
            toolTipMessage: '',
            filterByCategory: false,
            clearOnParentChanged: false,
          }
        }

        if (formStep.type === 'realtorTextTypeahead') {
          formStep.parentClass = formStep.parentClass || 'w-1/2 mb-2'
        }

        if (formStep.id === 'variance') {
          formStep.parentClass =
            formStep.parentClass ||
            'w-full flex justify-end absolute top-0 right-4'
          formStep.settings = {
            ...formStep.settings,
            titleSize: LabelSizes.small,
            prefix: 'No',
            suffix: 'Yes',
            textSize: LabelSizes.small,
            visible: this.instance.hideVarianceField(),
          }
        }

        if (formStep.type === 'fileUpload') {
          if (this.instance.shouldSetDocumentRequiredBasedOnLotProfile()) {
            formStep.settings = {
              ...formStep.settings,
              validators: [
                ...formStep.settings.validators,
                { name: 'required' },
              ],
            }
          }
        }

        if (VARIANCE_INFO.includes(formStep.id)) {
          formStep.parentClass = formStep.parentClass || 'w-full pb-2'
          formStep.settings = {
            ...formStep.settings,
            visible: this.instance.hideVarianceReasonField(),
            validators: [...formStep.settings.validators, { name: 'required' }],
          }
          formStep.required = !this.instance.hideVarianceField()
        }
      }

      return formStep
    })
    return processedMetadata
  }

  private createModel(metadata: any[]) {
    let model = {}
    metadata.forEach((formStep) => {
      const { step, nested, controls } = formStep
      if (step) {
        model = {
          ...model,
          ...{ [formStep.step]: { ...this.createModel(controls) } },
        }
      } else if (nested) {
        model = {
          ...model,
          ...{ [formStep.id]: { ...this.createModel(controls) } },
        }
      } else {
        model[formStep.id] = formStep.type === 'toggle' ? false : ''
      }
    })
    return model
  }

  /**
   * @description Perform a search inside the metadata seeking preview fields
   * @param {Object} metadata Metadata
   * @param {Object} model Current model based on the metadata
   * @param {String} path Path to get each field
   * @param {Array} fields Fields to be reviewed
   * @returns {Array} fields collection of preview fields
   */
  private previewFields(
    metadata: any,
    model: any,
    path: string = '',
    fields: any = []
  ) {
    metadata.forEach((stepForm: any) => {
      const { step, label, title, nested, controls } = stepForm
      if (step) {
        fields = [
          ...this.previewFields(
            controls,
            model[step],
            path !== '' ? path + '.' + title : title,
            fields
          ),
        ]
      } else if (nested) {
        const customLabel = label !== '' ? label : title
        fields = [
          ...this.previewFields(
            controls,
            model[stepForm.id],
            path !== '' ? path + '.' + customLabel : customLabel,
            fields
          ),
        ]
      } else {
        const customField = []
        if (stepForm?.settings?.preview) {
          let customLabel = path
          if (customLabel.includes('.')) {
            customLabel =
              customLabel.split('.')[customLabel.split('.').length - 1]
          }
          customField.push({
            id: stepForm.id,
            type: stepForm.type,
            label: customLabel + ' ' + label,
            value: model[stepForm.id],
          })
        }
        fields = [...fields, ...customField]
      }
    })

    return fields
  }

  /**
   * @description Process previews fields to be returned in a collection of two item per line
   * @param {Object} options Need a config object with the form model, form metadata and also an instance of the form in use
   * @returns {Array} A collection of preview fiels to be displayed in the page
   */
  getPreviewFieldsInformation = (options: {
    formModel: any
    metadata: any
    formInstance: any
  }) => {
    const { formModel, metadata, formInstance } = options
    const results = this.previewFields(metadata, formModel)
    return results
      .map((field: any) => {
        if (field.type === 'elevations') {
          let elevation = null
          if (formInstance.elevations && formInstance.elevations.length > 0) {
            elevation = formInstance.elevations.find(
              (elevation: any) => elevation.id === field.value
            )
            field.value = elevation?.plan?.name || '-'
          }
          return [
            {
              label: 'Architectural Style',
              value:
                formInstance.selectedStyle > 0 && formInstance.styles
                  ? formInstance.styles.find(
                      (style: any) => style.id === formInstance.selectedStyle
                    )?.name
                  : '',
            },
            {
              label: 'Elevation Name',
              value: elevation ? elevation?.name : '-',
            },
            { ...field },
          ]
        }
        if (field.type === 'currency') {
          field.value = field.value
            ? formInstance.$options.filters.currency(field.value)
            : '-'
        }
        if (field.type === 'materials') {
          if (formInstance.materialsNotFiltered[field.id]?.length) {
            field.value = formInstance.materialNameAndBrand(
              formInstance.materialsNotFiltered[field.id],
              field.value
            )
          } else {
            field.value = '-'
          }
        }
        return { ...field }
      })
      .flat()
      .map((field: any, index: number) => {
        if (index !== 0 && index % 2 === 0) {
          return [{ show: false }, { ...field, show: true }]
        }
        return [{ ...field, show: true }]
      })
      .flat()
  }

  private findFieldMetadata(id: string) {
    return this.steps
      .flatMap((step: FormStep) => step.controls)
      .find((field) => field.id === id)
  }

  private processValidators(metadata: any[]) {
    let validatorsForm = {}
    metadata.forEach((formStep) => {
      const { step, nested, controls } = formStep
      if (step) {
        validatorsForm = {
          ...validatorsForm,
          ...{
            [formStep.step]: {
              ...this.processValidators(controls),
            },
          },
        }
      } else if (nested) {
        validatorsForm = {
          ...validatorsForm,
          ...{
            [formStep.id]: {
              ...this.processValidators(controls),
            },
          },
        }
      } else {
        formStep.settings.validators &&
          formStep.settings.validators.forEach((validator: any) => {
            switch (validator.name) {
              case 'required': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  required: helpers.withMessage(
                    `${formStep.label} is required`,
                    required
                  ),
                }
                break
              }
              case 'minLength': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  minLength: helpers.withMessage(
                    `${formStep.label} has a minimun lenght of ${validator.value}`,
                    minLength(validator.value)
                  ),
                }
                break
              }
              case 'maxLength': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  maxLength: helpers.withMessage(
                    `${formStep.label} has a maximun lenght of ${validator.value}`,
                    maxLength(validator.value)
                  ),
                }
                break
              }
              case 'minValue': {
                if (formStep.type === 'date') {
                  const validFunction = () => {
                    return (value: any) => {
                      const date = this.getModelValue(
                        this.formModel,
                        validator.value
                      )
                      return new Date(date) < new Date(value)
                    }
                  }
                  const label =
                    this.findFieldMetadata(validator.value)?.label ||
                    validator.name

                  validatorsForm[formStep.id] = {
                    ...validatorsForm[formStep.id],
                    minValue: helpers.withMessage(
                      `${formStep.label} must be higher than ${label}`,
                      (value: string) => {
                        return validFunction()(value)
                      }
                    ),
                  }
                } else {
                  validatorsForm[formStep.id] = {
                    ...validatorsForm[formStep.id],
                    minValue: helpers.withMessage(
                      `${formStep.label} is less than the minimun value of ${validator.value}`,
                      minValue(validator.value)
                    ),
                  }
                }
                break
              }
              case 'maxValue': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  maxValue: helpers.withMessage(
                    `${formStep.label} is bigger than the maximun value of ${validator.value}`,
                    maxValue(validator.value)
                  ),
                }
                break
              }
              case 'between': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  between: helpers.withMessage(
                    `${formStep.label} needs to be between ${validator.min} and ${validator.max}`,
                    between(validator.min, validator.max)
                  ),
                }
                break
              }
              case 'alpha': {
                const alphabetical = helpers.regex(/^[A-Za-z]+(\s)*/)
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  alphabetical: helpers.withMessage(
                    `${formStep.label} may contain alphabetical characters and spaces`,
                    alphabetical
                  ),
                }
                break
              }
              case 'alphaNum': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  alphaNum: helpers.withMessage(
                    `${formStep.label} must be alpha-numeric`,
                    alphaNum
                  ),
                }
                break
              }
              case 'numeric': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  numeric: helpers.withMessage(
                    `${formStep.label} must be numeric`,
                    numeric
                  ),
                }
                break
              }
              case 'integer': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  integer: helpers.withMessage(
                    `${formStep.label} must be an integer`,
                    integer
                  ),
                }
                break
              }
              case 'decimal': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  decimal: helpers.withMessage(
                    `${formStep.label} must be an decimal`,
                    decimal
                  ),
                }
                break
              }
              case 'url': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  url: helpers.withMessage(
                    `${formStep.label} must be an url`,
                    url
                  ),
                }
                break
              }
              case 'email': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  email: helpers.withMessage(
                    `${formStep.label} must be an email`,
                    email
                  ),
                }
                break
              }
              case 'zipcode': {
                const zipcode = helpers.regex(/^\d{5}?$/)
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  zipcode: helpers.withMessage(
                    `${formStep.label} must be compliant with a 5 digit number`,
                    zipcode
                  ),
                  asyncIsValid: helpers.withMessage(
                    ({ $pending, $model }) => {
                      if (!$pending) {
                        return `This is not a valid zipcode ${$model}`
                      }
                      return ''
                    },
                    helpers.withAsync(async (value: string) => {
                      if (!value) {
                        return true
                      }
                      const data = await isValidZipcode(value)
                      return data.valid
                    })
                  ),
                }
                break
              }
              case 'phone': {
                const phone = helpers.regex(/^\(?\d{3}\)?[- ]?\d{3}[- ]?\d{4}$/)
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  phone: helpers.withMessage(
                    `${formStep.label} must be compliant with a phone number`,
                    phone
                  ),
                }
                break
              }
              case 'backdatedSaleContract': {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                  backdatedSaleContract: helpers.withMessage(
                    isValidSoldDateForCustomContractMessage(
                      this.instance.project.config
                        .enable_sales_submission_restriction
                    ),
                    isValidSoldDateForCustomContract(
                      this.instance.project.config
                        .enable_sales_submission_restriction
                    )
                  ),
                }
                break
              }
              default: {
                validatorsForm[formStep.id] = {
                  ...validatorsForm[formStep.id],
                }
              }
            }
          })
      }
    })

    return validatorsForm
  }

  /**
   * @description Process the metadata field looking for dependent fields
   * @param {Object} metadata Metadata
   * @param {string} path path of the field
   * @param {Array} trackingPathFields the list of tracked fields
   */
  processWatchers(
    metadata: any[],
    path: string = '',
    trackingPathFields: Array<string> = []
  ) {
    metadata.forEach((formStep) => {
      const { step, nested, controls } = formStep
      if (step) {
        this.processWatchers(controls, formStep.step, trackingPathFields)
      } else if (nested) {
        this.processWatchers(
          controls,
          path + '.' + formStep.id,
          trackingPathFields
        )
      } else {
        trackingPathFields.push(path + '.' + formStep.id)

        const hasCustomWatcher: boolean = formStep?.settings?.custom_watcher
        if (hasCustomWatcher) {
          let cleanID: string = formStep.id.toLowerCase()
          if (cleanID.includes('__')) {
            cleanID = cleanID.replace('__', '')
          }

          const watcherName: string = `watcher${this.capitalize(cleanID)}`
          const customFunction: Function = (this.instance as any)[watcherName]
          if (customFunction && typeof customFunction === 'function') {
            this.instance.$watch(
              this.modelName + '.' + path + '.' + formStep.id, // where it should be watching
              (value: string, oldValue: string) => {
                if (value && oldValue && value !== oldValue) {
                  customFunction(value)
                }
                this.instance.v$.value && this.instance.v$.value.$validate()
              }
            )
          }
        }

        if (formStep.settings?.depends_on) {
          const pathFound = trackingPathFields.find((trackPath) =>
            trackPath.includes(formStep.settings?.depends_on)
          )

          if (pathFound) {
            if (
              formStep.type === 'elevations' &&
              pathFound.includes('variance')
            ) {
              this.instance.$watch(
                this.modelName + '.' + pathFound, // where it should be watching
                async (value: boolean, oldValue: boolean) => {
                  await this.instance.onVarianceRequestChanged(value, oldValue)
                }
              )
            } else if (
              VARIANCE_INFO.includes(formStep.id) &&
              pathFound.includes('variance')
            ) {
              this.instance.$watch(
                this.modelName + '.' + pathFound,
                async (value: boolean) => {
                  await this.instance.showVarianceReasonField(
                    value,
                    formStep.id
                  )
                }
              )
            } else if (formStep.type === 'materials') {
              this.instance.$watch(
                this.modelName + '.' + pathFound, // where it should be watching
                async (value: string, oldValue: string) => {
                  if (value) {
                    await this.instance.onElevationChange(
                      value,
                      formStep.settings?.category_set,
                      formStep.settings?.materials_controller,
                      formStep.id,
                      oldValue
                    )
                  }
                }
              )
            } else if (
              pathFound.includes('elevation_id') &&
              formStep.type !== 'materials'
            ) {
              this.instance.$watch(
                this.modelName + '.' + pathFound, // where it should be watching
                (value: string) => {
                  if (value) {
                    const elevation = this.instance.selectedElevation(value)
                    this.updateFieldValueModel(
                      this.instance[this.modelName],
                      formStep.id,
                      elevation?.plan?.price || ''
                    )
                  }
                  this.instance.v$.value && this.instance.v$.value.$validate()
                }
              )
            } else {
              this.instance.$watch(
                this.modelName + '.' + pathFound, // where it should be watching
                (value: string) => {
                  if (value) {
                    this.updateFieldValueModel(
                      this.instance[this.modelName],
                      formStep.id,
                      value
                    )
                  }
                  this.instance.v$.value && this.instance.v$.value.$validate()
                }
              )
            }
          }
        }
      }
    })
  }

  processADynamicWatcher(
    model: any,
    propertyToBeWatch: string,
    path: string = '',
    callback: Function
  ) {
    let watcher = null
    const modelKeys = Object.keys(model)
    if (modelKeys.length && modelKeys.includes(propertyToBeWatch)) {
      let property = path || this.modelName
      property += '.' + propertyToBeWatch
      watcher = this.instance.$watch(property, (newValue: any, oldValue: any) =>
        callback(newValue, oldValue)
      )
    } else {
      const modelList = Object.values(model)
      modelList.forEach((deepModel: any, index) => {
        if (
          typeof deepModel === 'object' &&
          deepModel !== null &&
          Object.keys(deepModel).length
        ) {
          watcher = this.processADynamicWatcher(
            deepModel,
            propertyToBeWatch,
            path
              ? path + '.' + modelKeys[index]
              : this.modelName + '.' + modelKeys[index],
            callback
          )
        }
      })
    }
    return watcher
  }

  getModelValue(model: any, property: string, value: any = null) {
    const modelKeys = Object.keys(model)
    if (modelKeys.length && modelKeys.includes(property) && model[property]) {
      value = model[property]
    } else {
      const modelList = Object.values(model)
      modelList.forEach((deepModel: any) => {
        if (
          typeof deepModel === 'object' &&
          deepModel !== null &&
          Object.keys(deepModel).length
        ) {
          value = this.getModelValue(deepModel, property, value)
        }
      })
    }
    if (value) {
      return value
    }
  }

  updateFieldValueModel(
    model: any,
    property: string,
    value: any,
    modelName: string = ''
  ) {
    const modelKeys = Object.keys(model)
    if (modelKeys.length && modelKeys.includes(property)) {
      if (
        (this.instance.isEdition && model[property] !== undefined) ||
        !this.instance.isEdition
      ) {
        model[property] = value
      }
    } else {
      const modelList = Object.values(model)
      modelList.forEach((deepModel: any) => {
        const isObject = typeof deepModel === 'object' && deepModel !== null
        if (isObject) {
          if (modelName) {
            const foundModel =
              deepModel[modelName] !== undefined &&
              typeof deepModel[modelName] === 'object'

            if (foundModel) {
              this.updateFieldValueModel(deepModel[modelName], property, value)
            }
          } else {
            this.updateFieldValueModel(deepModel, property, value, modelName)
          }
        }
      })
    }
  }

  processOptionalRequiredFields(
    metadata: any[],
    path: string = '',
    trackingPathFields: Array<string> = []
  ) {
    metadata.forEach((formStep) => {
      const { step, nested, controls } = formStep
      if (step) {
        this.processOptionalRequiredFields(
          controls,
          formStep.step,
          trackingPathFields
        )
      } else if (nested) {
        this.processOptionalRequiredFields(
          controls,
          path + '.' + formStep.id,
          trackingPathFields
        )
      } else {
        trackingPathFields.push(path + '.' + formStep.id)
        if (formStep.settings?.is_required_based_on) {
          const pathFound = trackingPathFields.find((trackPath) =>
            trackPath.includes(formStep.settings?.is_required_based_on)
          )
          if (pathFound) {
            this.instance.$watch(
              this.modelName + '.' + pathFound, // where it should be watching
              (value: any, oldValue: any) => {
                const relatedFields = trackingPathFields.filter((trackPath) =>
                  trackPath.includes(formStep.id)
                )

                if (relatedFields.length <= 1) {
                  this.manageRequiredFields(
                    formStep,
                    relatedFields.pop(),
                    value,
                    oldValue
                  )
                  this.instance.updateValidatorInstance(
                    this.instance.validators
                  )
                  return
                }

                // by landing in this block that means there are more than one field with the same id
                const is_required_based_on =
                  formStep.settings.is_required_based_on.split('.')
                if (is_required_based_on.length > 1) {
                  is_required_based_on.pop()
                }

                const parentField = is_required_based_on.pop()
                const parentFieldMetadata = this.getParentField(parentField)
                const hasMultipleRequiredFields =
                  parentFieldMetadata?.settings?.is_multiple_required_based_on

                if (hasMultipleRequiredFields) {
                  relatedFields.forEach((field) => {
                    this.manageRequiredFields(formStep, field, value, oldValue)
                  })
                } else {
                  const pivotField = relatedFields.find((trackPath) =>
                    trackPath.includes(parentField)
                  )

                  if (!pivotField) {
                    return
                  }

                  this.manageRequiredFields(
                    formStep,
                    pivotField,
                    value,
                    oldValue
                  )
                }

                this.instance.updateValidatorInstance(this.instance.validators)
              }
            )
          }
        }
      }
    })
  }

  private getParentField(parentId: string) {
    if (!this.formMetadata) {
      return
    }
    const fieldMetadata = this.formMetadata.find((formStep) => {
      const controls = formStep.controls
      if (!controls) {
        return false
      }
      return controls.find((control) => control.id === parentId)
    })

    const parentField = fieldMetadata.controls.find((control) => {
      return control.id === parentId
    })

    return parentField
  }

  private manageRequiredFields(
    formStep: any,
    pivotField: string,
    value: any,
    oldValue: any
  ) {
    const path = pivotField.split('.')
    path.pop()

    // try to find if the validator already exists
    let validador = this.findValidator(
      this.instance.validators,
      path[path.length - 1]
    )
    validador = validador[formStep.id]

    // in case value is true or false but as string we need to convert it
    if (typeof value === 'string') {
      if (value.toLowerCase() === 'true') {
        value = true
      } else if (value.toLowerCase() === 'false') {
        value = false
      }
    }

    const valueChanged = !!value && value !== oldValue
    const reversed = formStep.settings.reversed_required_based_on || false

    if (reversed) {
      const hasRequired =
        validador && Object.keys(validador).includes('required')
      if (valueChanged && (value || value.toLowerCase() === 'yes')) {
        if (hasRequired) {
          delete validador.required
        }
      } else if (!value || value.toLowerCase() === 'no') {
        if (!hasRequired) {
          this.addRequiredValidator(formStep, path)
        }
      }
    } else if (!reversed) {
      // this is the old way the field are maaged
      if (valueChanged) {
        if (
          !validador ||
          (!Object.keys(validador).includes('required') &&
            (value || value.toLowerCase() === 'yes'))
        ) {
          const parentValidator = this.findValidator(
            this.instance.validators,
            path[path.length - 1]
          )
          parentValidator[formStep.id] = {
            ...parentValidator[formStep.id],
            required: helpers.withMessage(
              `${formStep.label} is required`,
              required
            ),
          }
        }
      } else if (validador && (!value || value.toLowerCase() === 'no')) {
        if (Object.keys(validador).includes('required')) {
          delete validador.required
        }
      }
    }
  }

  addRequiredValidator(formStep: any, path: string[]) {
    const parentValidator = this.findValidator(
      this.instance.validators,
      path[path.length - 1]
    )

    parentValidator[formStep.id] = {
      ...parentValidator[formStep.id],
      required: helpers.withMessage(`${formStep.label} is required`, required),
    }
  }

  findValidator(validators: any, property: string, value: any = undefined) {
    const modelKeys = Object.keys(validators)
    if (modelKeys.length && modelKeys.includes(property)) {
      if (validators[property] !== undefined) {
        value = validators[property]
      }
    } else {
      const modelList = Object.values(validators)
      modelList.forEach((deepModel: any) => {
        if (
          typeof deepModel === 'object' &&
          deepModel !== null &&
          Object.keys(deepModel).length
        ) {
          value = this.findValidator(deepModel, property, value)
        }
      })
    }

    if (value) {
      return value
    }
  }

  getMarkegintParentController(step: any) {
    if (!step.controls) {
      return null
    }

    const parentController = step.controls.find((control: any) => {
      const found = control.controls.find((nestedControl: any) => {
        return nestedControl.type === InputType.marketingInput
      })
      if (found) {
        return found.id
      }
      return null
    })

    return parentController ? parentController.id : null
  }

  private capitalize(value: string) {
    if (typeof value !== 'string' || value.length === 0) {
      return ''
    }
    return value.charAt(0).toUpperCase() + value.slice(1)
  }

  get model() {
    return this.formModel
  }

  get steps(): FormStep[] {
    return this.formMetadata
  }

  get validators(): any {
    return this.validatorsForm
  }
}
