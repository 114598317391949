









































































import { Component, Emit, Prop, Vue } from 'nuxt-property-decorator'
import MapDropdownFilter from '~/components/sales_map_v3/MapDropdownFilter.vue'
import MapSelect from '~/components/consumer_map/mapSelect.vue'

@Component({
  components: {
    MapDropdownFilter,
    MapSelect,
  },
})
export default class CustomFilters extends Vue {
  @Prop({ default: '' }) title: String
  @Prop({ default: '' }) openedMenu: String
  @Prop({ default: (): any => {} }) customFilters: any
  @Prop({ default: (): any => {} }) selectedOptions: any

  mounted() {
    this.customFilters.controls?.forEach((filter: any) => {
      if (!filter.nested) {
        filter.settings.options?.forEach((option: any) => {
          option.type = filter.id
          option.classification = 'custom'
        })
      }
      if (filter.nested) {
        filter.controls.forEach((nestedFilter: any) => {
          nestedFilter.settings?.options?.forEach((option: any) => {
            option.type = nestedFilter.id
            option.classification = 'custom'
          })
        })
      }
    })
  }

  get sortedFilterControls(): any {
    const sortControls = (controls: any[]) => {
      return controls.sort((controlA: any, controlB: any) => {
        return controlA.order - controlB.order
      })
    }

    const filters = sortControls(this.customFilters.controls)

    filters.forEach((filter: any) => {
      if (filter.nested) {
        filter.controls = sortControls(filter.controls)
      }
    })

    return filters
  }

  get isScreenLarge(): boolean {
    return window.innerWidth >= 1024
  }

  @Emit('handleCheck')
  handleCheck(selectedOptions: [], filterId: string) {
    const selectedOptionCleared = this.selectedOptions.filter(
      (option: any) => option.type !== filterId
    )
    const options = new Set([...selectedOptionCleared, ...selectedOptions])
    return options
  }

  @Emit('changeMenu')
  handleChangeMenu(e: any) {
    return e || ''
  }
}
